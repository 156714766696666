import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Collapse,
} from "reactstrap"
import AHDCIcon from "../img/icons/ahdc-icons"

const SERVICES_QUERY = graphql`
  query IServices {
    wpgraphql {
      page(id: "cG9zdDo4") {
        acf_strategy_fields {
          serviceItems {
            service
            serviceDescription
            serviceIcon
          }
        }
      }
    }
  }
`
class IntegratedServices extends Component {
  constructor(props) {
    super(props)
    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.state = {
      accordion: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    }
  }

  toggleAccordion(tab) {
    const prevState = this.state.accordion
    const state = prevState.map((x, index) => (tab === index ? !x : false))

    this.setState({
      accordion: state,
    })
  }

  render() {
    return (
      <StaticQuery
        query={SERVICES_QUERY}
        render={data => {
          return (
            <Container fluid={true}>
              <Row>
                <Col
                  md={{ offset: 1, size: 10 }}
                  lg={{ offset: 0, size: 6 }}
                  className="servicecard"
                >
                  {data.wpgraphql.page.acf_strategy_fields.serviceItems.map(
                    (item, i) => (
                      <div key={item.service} className="service__block mb-1">
                        {i <= 4 && (
                          <>
                            <Button
                              onClick={() => this.toggleAccordion(i)}
                              aria-expanded={this.state.accordion[i]}
                              aria-controls={`collapse${i}`}
                              color="link"
                              className={`heading4 arrow-open-${this.state.accordion[i]}`}
                            >
                              <AHDCIcon
                                name={item.serviceIcon}
                                color="#17ac52"
                              />

                              {item.service}
                            </Button>
                            <Collapse
                              isOpen={this.state.accordion[i]}
                              data-parent="#accordion"
                              id={`collapse${i}`}
                              aria-labelledby={`heading${i}`}
                              className="how-we-work__card-wrapper"
                            >
                              <Card>
                                <CardBody>{item.serviceDescription}</CardBody>
                              </Card>
                            </Collapse>
                          </>
                        )}
                      </div>
                    )
                  )}
                </Col>

                <Col
                  md={{ offset: 1, size: 10 }}
                  lg={{ offset: 0, size: 6 }}
                  className="servicecard"
                >
                  {data.wpgraphql.page.acf_strategy_fields.serviceItems.map(
                    (item, i) => (
                      <>
                        {i >= 5 && (
                          <div
                            key={item.service}
                            className="service__block mb-1"
                          >
                            <Button
                              onClick={() => this.toggleAccordion(i)}
                              aria-expanded={this.state.accordion[i]}
                              aria-controls={`collapse${i}`}
                              color="link"
                              className={`heading4 arrow-open-${this.state.accordion[i]}`}
                            >
                              <AHDCIcon
                                name={item.serviceIcon}
                                color="#17ac52"
                              />

                              {item.service}
                            </Button>
                            <Collapse
                              isOpen={this.state.accordion[i]}
                              data-parent="#accordion"
                              id={`collapse${i}`}
                              aria-labelledby={`heading${i}`}
                              className="how-we-work__card-wrapper"
                            >
                              <Card>
                                <CardBody>{item.serviceDescription}</CardBody>
                              </Card>
                            </Collapse>
                          </div>
                        )}
                      </>
                    )
                  )}
                </Col>
              </Row>
            </Container>
          )
        }}
      />
    )
  }
}

export default IntegratedServices
