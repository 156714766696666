import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import IntegratedServices from "../components/strategy/accordion"
import { Container, Row, Col } from "reactstrap"
import Img from "gatsby-image"
import Quotes from "../components/quotes"
import { Parallax } from "react-parallax"
import { animated, useSpring } from "react-spring"
import { Waypoint } from "react-waypoint"
import BarExpand from "../components/img/bar-expand"
import BarExpandSpan from "../components/img/bar-expand-span"

export const query = graphql`
  query {
    wpgraphql {
      page(id: "cG9zdDo4") {
        featuredImage {
          node {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        acf_page_meta {
          metadescription
          metatitle
        }
        acf_strategy_fields {
          capacitiesHead
          capacitiesText
          approachHead
          approachText
          commitmentHead
          commitmentText
          serviceItems {
            service
            serviceDescription
          }
          valueHead
          valueItem {
            valueSubhead
            valueText
          }
          image {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          secondImage {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          thirdImage {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fourthImage {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fifthImage {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          successHead
          successImage {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          statistics {
            statisticHead
            statisticText
          }
        }
      }
    }
  }
`
const Strategy = ({ data, location }) => {
  const insideStyles = {
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "80%",
    transform: "translate(-50%,-50%)",
  }
  const image1 = "/images/backgrounds/strategy-stats-bkgnd-min.jpg"
  const [on, toggle] = useState(false)

  const fadein = useSpring({
    opacity: on ? 1 : 0,
    from: { opacity: 0, transform: "translate3d(0,50px,0)" },
    to: {
      opacity: on ? 1 : 0,
      transform: on ? "translate3d(0,0,0px)" : "translate3d(0,50px,0)",
    },
    config: { tension: 100 },
  })
  const topphoto = useSpring({
    //opacity: on ? 1 : 0,
    transform: on ? "translate(0%,0)" : "translate(50%,0)",
    width: on ? "100%" : "50%",
    //overflow: on ? "hidden" : "hidden",
    config: { tension: 100 },
  })

  const page = data.wpgraphql.page
  return (
    <Layout navlocation={location.pathname}>
      <SEO
        title={data.wpgraphql.page.acf_page_meta.metatitle}
        description={data.wpgraphql.page.acf_page_meta.metadescription}
      />
      <Waypoint
        onEnter={() => {
          if (!on) toggle(true)
        }}
      />
      <header>
        {page.featuredImage != null && (
          <Img
            className="header__image"
            alt={page.featuredImage.node.altText}
            fluid={page.featuredImage.node.imageFile.childImageSharp.fluid}
          />
        )}

        <Container fluid={true} className="container__page">
          <Row>
            <Col md={{ offset: 6, size: 6 }} className="px-0">
              <animated.div style={fadein}>
                <h1 className="heading1 long-heading heading-reverse">
                  Where Experience <span>Meets Innovation.</span>
                </h1>
              </animated.div>
              <BarExpandSpan class="header--block block-right" width="103" />
            </Col>
          </Row>
        </Container>
      </header>

      <Row className="strategy__card">
        <Col md={{ size: 12 }} className="px-0 py-3">
          <BarExpand
            width="10"
            text="Our Strategy"
            class="text-secondary bar-hide"
            spanclass="service-header"
          />
        </Col>
        <Row className="p-2">
          <Col
            md={{ offset: 0, size: 6 }}
            lg={{ offset: 0, size: 4 }}
            className="pb-3"
          >
            <h3 className="text-primary ">
              {page.acf_strategy_fields.approachHead}
            </h3>
            <p>{page.acf_strategy_fields.approachText}</p>
          </Col>
          <Col
            md={{ offset: 0, size: 6 }}
            lg={{ offset: 0, size: 4 }}
            className="pb-3"
          >
            <h3 className="text-primary">
              {page.acf_strategy_fields.capacitiesHead}
            </h3>
            <p>{page.acf_strategy_fields.capacitiesText}</p>
          </Col>
          <Col
            md={{ offset: 0, size: 6 }}
            lg={{ offset: 0, size: 4 }}
            className="pb-3"
          >
            <h3 className="text-primary">
              {page.acf_strategy_fields.commitmentHead}
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: page.acf_strategy_fields.commitmentText,
              }}
            />
          </Col>
          <Col
            md={{ offset: 0, size: 6 }}
            lg={{ offset: 0, size: 4 }}
            className="pb-2"
          >
            <h3 className="text-primary margin-adjust">Our Environment </h3>
            <p>
              We partner with knowledgeable and award-winning sustainable design
              professionals and Green Building technology consultants to ensure
              efficient and effective design. We are striving to be Net Zero on
              many of our new construction developments and go beyond current
              Title 24 requirements to incorporate measures that create energy
              efficiency saving for our residents as well as the overall
              operations. Some of these measures include: triple pane windows
              with low U-factors and ratings that exceed building code, radiant
              barrier roofing and cool roofing, energy efficiency appliances,
              including HVAC and water heaters, LED lighting throughout the
              units and site design, large Photovoltaic systems that are
              designed early on in the design process to ensure efficient
              building orientation.
            </p>
          </Col>
          <Col
            md={{ offset: 0, size: 6 }}
            lg={{ offset: 0, size: 4 }}
            className="pb-2"
          >
            <Img
              className="strategy__hero_photo"
              alt={page.acf_strategy_fields.image.altText}
              fluid={
                page.acf_strategy_fields.image.imageFile.childImageSharp.fluid
              }
            />
          </Col>
          <Col
            md={{ offset: 0, size: 6 }}
            lg={{ offset: 0, size: 4 }}
            className="pb-2"
          >
            <Img
              className="strategy__hero_photo"
              alt={page.acf_strategy_fields.image.altText}
              fluid={
                page.acf_strategy_fields.secondImage.imageFile.childImageSharp
                  .fluid
              }
            />
          </Col>
          <Col
            md={{ offset: 0, size: 6 }}
            lg={{ offset: 0, size: 4 }}
            className="pb-2"
          >
            <Img
              className="strategy__hero_photo"
              alt={page.acf_strategy_fields.image.altText}
              fluid={
                page.acf_strategy_fields.thirdImage.imageFile.childImageSharp
                  .fluid
              }
            />
          </Col>
          <Col
            md={{ offset: 0, size: 6 }}
            lg={{ offset: 0, size: 4 }}
            className="pb-2"
          >
            <Img
              className="strategy__hero_photo"
              alt={page.acf_strategy_fields.image.altText}
              fluid={
                page.acf_strategy_fields.fourthImage.imageFile.childImageSharp
                  .fluid
              }
            />
          </Col>
          <Col
            md={{ offset: 0, size: 6 }}
            lg={{ offset: 0, size: 4 }}
            className="pb-2"
          >
            <Img
              className="strategy__hero_photo"
              alt={page.acf_strategy_fields.image.altText}
              fluid={
                page.acf_strategy_fields.fifthImage.imageFile.childImageSharp
                  .fluid
              }
            />
          </Col>
        </Row>
      </Row>

      <Waypoint
        onLeave={() => {
          if (on) toggle(false)
        }}
      />
      <Row className="parallax__card_strategy_developed">
        <Col className="px-0 text-center">
          <Parallax bgImage={image1} strength={300}>
            <div style={{ height: 320 }}>
              <div style={insideStyles}>
                <h3 className="">
                  We have developed <b>85</b> projects in <b>47</b> cities and{" "}
                  <b>29</b> counties over <b>30</b> years.
                </h3>
              </div>
            </div>
          </Parallax>
        </Col>
      </Row>

      <Row className="strategy__card_vertical">
        <Col md={{ size: 12 }} className="px-0 py-3">
          <BarExpand
            width="15"
            text="Vertically Integrated Services"
            class="text-white"
            spanclass="service-header"
          />
          <div className="py-1 px-0 p-lg-3">
            <IntegratedServices />
          </div>
        </Col>
      </Row>

      <Row className="bg-light mt-3 pb-5 strategy__create_value">
        <Col md={{ size: 12 }} className="px-0 py-3">
          <BarExpand
            width="19"
            text={page.acf_strategy_fields.valueHead}
            class="text-secondary"
            spanclass="service-header"
          />
        </Col>

        <Col md={{ size: 8, offset: 2 }} className="">
          {page.acf_strategy_fields.valueItem.map((item, i) => (
            <div className="pb-2">
              <h3 className="text-primary">{item.valueSubhead}</h3>
              <p>{item.valueText}</p>
            </div>
          ))}
        </Col>
      </Row>

      <Waypoint
        bottomOffset="15%"
        onEnter={() => {
          if (!on) toggle(true)
        }}
      />
      <animated.div style={fadein}>
        <Row className="bg-secondary strategy__financing-success">
          <Col md={{ size: 4, offset: 2 }} className="py-3">
            <h3 className="text-white">
              {page.acf_strategy_fields.successHead}
            </h3>

            {page.acf_strategy_fields.statistics.map((item, i) => (
              <div className="py-1">
                <h3 className="text-primary py-0">{item.statisticHead}</h3>
                <p className="text-white my-0 py-0">{item.statisticText}</p>
              </div>
            ))}
          </Col>
          <Col md={{ offset: 2, size: 4 }} className="p-0 pb-lg-2 text-right">
            <div className="strategy__success_photo-wrapper pb-md-3">
              <animated.div style={topphoto} className="animated-right">
                <Img
                  className="strategy__success_photo"
                  alt={page.acf_strategy_fields.successImage.altText}
                  fluid={
                    page.acf_strategy_fields.successImage.imageFile
                      .childImageSharp.fluid
                  }
                />
              </animated.div>
            </div>
          </Col>
        </Row>
      </animated.div>
      <Quotes id="cG9zdDoyMDA=" />
    </Layout>
  )
}

export default Strategy
